import CustomInput from "../custom-input/CustomInput";
import CustomDropdown from "../custom-input/CustomDropdown";
import { useEffect, useState } from "react";
import CustomButton from "../custom-button/CustomButton";
import CustomCard from "../custom-card/CustomCard";
import useTranslation from "../../utils/translation";
import CustomFileInput from "../custom-input/CustomFileInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { SelectItem } from "../../types/MenuItems";
import useFileService from "../file/FileService";
import { ContentType, FileTypes } from "../../types/Enums";
import useCategoryService from "../category/CategoryService";
import { MediaContent } from "../../types/MediaContent";

interface ContentType {
  id: number,
  name: string,
  content: JSX.Element
}

function ContentMediaAdd() {
  const [fileComponent, setFileComponent] = useState<JSX.Element>()
  const { register, control, handleSubmit, formState: { errors },reset,getValues } = useForm<MediaContent>();
  const [categories, setCategories] = useState<SelectItem<string>[]>([]);

  const {translate} = useTranslation();
  const fileService = useFileService();
  const categoryService = useCategoryService();

  const contentTypes: SelectItem<number>[] = [
    { value: 100, label: "Video Content" },
    { value: 101, label: "Image Content" },
    { value: 102, label: "Document Content" },
    { value: 103, label: "Question" },
    { value: 104, label: "Youtube video" },

  ];
  let questionComponent = <></>
  const getCategoryData = (filterText: string) => {
    categoryService.getCategories({
      pageSize: 5,
      cursor: '',
      searchText: filterText
    }).then(({data: response} )=> {
      setCategories(response.results.map((resp) => ({
        label: resp.category_name,
        value: resp.id
      })))
    })
  
  };

  useEffect(() => {
    getCategoryData("")
  }, [])
  
  
  const urlComponent = (<CustomInput
    placeholder={translate('youtubeurl')}
    register={register}
    options={{
      required: 'url is required'
    }}
    label="url"
    error={errors.url}
  />)

  const fileComp = (content_type: keyof typeof FileTypes) => 
   <CustomFileInput 
    register={register}
    fileType={content_type}
    label="file_field"
    options={{
      required:  "file is required" 
    }}
    placeholder="File "
    onValueChange={handleImage2Change}
    error={errors?.file_field} />
  

  const handleContentType = (content_type?: keyof typeof FileTypes) => {
    console.log(content_type);
    if (content_type && [ContentType.IMAGE, ContentType.DOCUMENT, ContentType.VIDEO].some(value=> value === content_type) ){
      setFileComponent(fileComp(content_type));  
    } else if(content_type && content_type === ContentType.YOUTUBE_VIDEO){
      setFileComponent(urlComponent)
    } else {
      setFileComponent(<></>)
    }
  }

  const onSubmit : SubmitHandler<MediaContent> = (data) => {
    const formData = new FormData();
    console.log(data)
    data.file_field?.length && formData.append(
      "url",
    //   data?.file_field[0],
      (data.file_field[0] as unknown as File)?.name
    );
    formData.append("file_type",'101');
    // data.chapter = chapter;

    if([ContentType.IMAGE, ContentType.DOCUMENT, ContentType.VIDEO].some(value=> value === data.content_type)){
      fileService.addFile(formData).then(({data:value})=>{
        data.url = value.url
      //   contentService.addContent(data).then(result=>{
      //     setId(result.data.id)
      //     // appendContent(result.data)
      //     toast.success("succesfully added")
      //     reset()
      //     console.log(getValues("content_type"))
      //     loading.stopLoading()
      //  }).catch((error) =>  {console.error(error); loading.stopLoading()}); 
      });
    } else{
    //   contentService.addContent(data).then(result=>{
    //     toast.success("succesfully added")
    //     setId(result.data.id)
    //     // appendContent(result.data)
    //     reset()

    //     loading.stopLoading()
    //  }).catch((error) =>  {console.error(error); loading.stopLoading()});   
    };
  }
  
 const handleImageChange = (e:any) => {
    console.log(e)
  }

  const handleImage2Change = (e:any) => {
    console.log(e)
  }

  return (
    <>
      <CustomCard>
        <form onSubmit={handleSubmit(onSubmit)}>
        <CustomDropdown
              placeholder="Category"
              data={categories}
              register={register}
              control={control}
              label='category'
              options={{
                required: 'Category is required'
              }}
              isSearchable={true}
              error={errors.category}
            />
           <CustomInput
            placeholder={translate('title')}
            register={register}
            label="title"
            options={{
              required: "Title is required"
            }}
            error={errors.title}
          />
          
          <CustomFileInput 
              register={register}
              label="thumbnail_image"
              options={{
                required: "Post Image is required"
              }}
              placeholder="Post Image"
              fileType={101}
              onValueChange={handleImage2Change}
              error={errors?.thumbnail_image} />
          
          
           <CustomInput
            placeholder={translate('description')}
            register={register}
            label="description"
            options={{
              required: "Description is required"
            }}
            error={errors.description}
          />
           <CustomDropdown
            placeholder={translate("content_type")}
            data={contentTypes}
            key="name"
              register={register}
              label='content_type'
              options={{
                required: 'Content Type is required'
              }}
              isSearchable={false}
              control={control}
              error={errors.content_type}
              onValueChange={handleContentType}
          />  
          {fileComponent}
          <CustomButton type="submit" text="Add Content" />
          
        </form>
      </CustomCard>
    </>
  );
}

export default ContentMediaAdd;
