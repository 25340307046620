import { useEffect, useState } from 'react';
import Footer from '../components/footer/Footer';
import { useParams } from 'react-router-dom';
import useTranslation from '../utils/translation';
import { Blog } from '../types/Blog';
import Header from '../components/header/Header';
import CustomButton from '../components/custom-button/CustomButton';
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
} from 'react-icons/fi';
import author11 from '../assets/images/author-11.webp';
import { Reviewers } from '../components/Reviewers/Reviewers';
import { useBlogService } from '../components/blog/blogService';
import MediaContentType from './MediaContentType';
import { dateTimeFormatter } from '../utils/dateUtils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formatDistanceToNow } from 'date-fns';
import useLoading from '../components/loading/LoadingHook';

function CourseDescription({ description, blog_content }: { description: string|undefined, blog_content: string}) {
  const { translate } = useTranslation();
  const [content ,setContent] = useState('')
  const quillModules = {
    toolbar: false, // Set toolbar to false to remove it
  };
  const quillStyles ={
    border:'none'

  }
  const increaseFontSize = () => {
    // Assuming content is wrapped in a div
    const parsedContent = new DOMParser().parseFromString(blog_content, 'text/html');
    const contentDiv = parsedContent.querySelector('div');

    if (contentDiv) {
      // Increase font size to 18px (adjust as needed)
      contentDiv.style.fontSize = '18px';
      setContent(contentDiv.outerHTML);
    }
  };
  return (
    <div className="mb-4">
      {/* <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        {translate('description')}:
      </h3> */}
    {/* //  <p className="text-gray-500 mt-4">{description}</p> */}

      <p className="text-gray-500 mt-4 mb-10 max-h-[800px] overflow-y-scroll">
        <ReactQuill className="react-quill-font font-serif font-medium" style={quillStyles} modules={quillModules} readOnly theme="snow" value={blog_content} />
      </p>
 
    </div>
  );
}

function BlogDetail () {
  const [activeLink, setActiveLink] = useState(Number);
  const [url, setUrl] = useState('');
  const blogService = useBlogService();
  const [mediaContent, setBlog] = useState<Blog>();
  const [tab, setTab] = useState('description');
  const {translate} = useTranslation()
  let { id } = useParams();
  const loading = useLoading()


  useEffect(() => {
    loading.startLoading()
    blogService.getBlog(id?id:'').then(({data: content}) => {
      setBlog(content);
      setUrl(content?.url)
      setActiveLink(content?.content_type)
      loading.stopLoading()
    })
  }, []);

  const tabs: { [id: string]: JSX.Element } = {
    description: <CourseDescription description={mediaContent?mediaContent.description:''} blog_content={(mediaContent?mediaContent.blog_content:'')} />,
    // reviews: <Reviewers id={id} />,
  };

  const changeTab = (selTab: string) => {
    setTab(selTab);
  };
  const [isCopied, setIsCopied] = useState(false); // State to track copy status

  const handleCopy = () => {
    var url = ""
    if (!url) url = window.location.href; // Default to current page URL if no URL provided
    navigator.clipboard.writeText(url); // Copy URL to clipboard
    setIsCopied(true); // Update copy status
    setTimeout(() => setIsCopied(false), 2000); // Reset copy status after 2 seconds
  };
  
  const timeFormater=(time:string="2023-12-30T09:26:36.420674Z")=>{
        
    return formatDistanceToNow(new Date(time), { addSuffix: true });

  
}

  return (
    <>
      <Header title="My" underlined={translate('courses')} activeTab="courses" />
      <div className="mx-5 sm:mx-52 pt-20 flex my-36 md:my-60 flex-wrap flex-col xl:flex-row items-start">
        <div className="relative overflow-hidden basis-full lg:basis-7/12">
          <div className="pt-7 ">
            <CourseDescription description={mediaContent?mediaContent.description:''} blog_content={(mediaContent?mediaContent.blog_content:'')} />
          </div>
        </div>
        <div className="basis-full lg:basis-5/12">
            <div className="mt-12">
            <h2 className="text-2xl font-medium mt-7 text-custom_black leading-6">
                {mediaContent?.title}
              </h2>
              <div className="flex items-center pt-2 justify-between">
              <div className="flex-1 pl-4 pb-1">
                posted
                  { " " + timeFormater(mediaContent?.create_date)}
                </div>
              </div>
                <img src={url} className="mt-5 p-0 sm:p-10" alt="No Image file" width={400} height={200}/>

                <button onClick={handleCopy} className={`relative overflow-hidden w-full cursor-pointer mt-5 leading-[3.75rem] rounded-xl text-sm sm:text-lg sm:leading-[3.75rem] font-medium transition-all duration-300 ease-in inline-block px-8 whitespace-nowrap bg-custom_light_green text-custom_green-900 hover:text-white hover:bg-custom_green-900 border-solid ${isCopied ? 'copied' : ''}`}>
                  {isCopied ? 'Blog Link Copied' : 'Copy Blog Link'}
                </button>

              <ul className="flex justify-between mx-15 mt-6 flex-wrap">
                <li className="pt-2 mr-2">
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiFacebook></FiFacebook>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href='https://www.linkedin.com/'
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiLinkedin></FiLinkedin>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiTwitter></FiTwitter>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiInstagram></FiInstagram>
                    </i>
                  </a>
                </li>
              </ul>
            
            <div className='mr-0 mt-20'>
              <Reviewers id={id} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogDetail;
