import './App.css';
import Login from './pages/Login';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import AuthProvider from './components/auth/AuthProvider';
import About from './pages/About';
import FAQ from './pages/FAQ';
import './App.css';
import Homepage from './pages/Homepage';
import ErrorNotFoundPage from './pages/404Page';
import InstructorDashboard from './pages/InstructorDashboard';
import Overview from './components/Instructer/Overview';
import Toasts from './components/toast/ToastList';
import CategoryContainer from './pages/Category';
import UserPermissionList from './components/acl/UserPermissionList';
import UsersList from './components/acl/user/UserList';
import GroupPermissionList from './components/acl/group-permission/GroupPermissionList';
import GroupList from './components/acl/group-permission/GroupList';
import MediaContent from './pages/MediaContentDetail';
import MediaContentList from './pages/MediaContentList';
import ContactUs from './pages/ContactUs';
import ContentMediaList from './components/media-content/ContentMediaList';
import BlogDetail from './pages/BlogDetail';
import BlogList from './pages/BlogList';
import Home from './pages/Home';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/media_content_list',
    element: <MediaContentList />
  },
  {
    path: '/media_content_detail/:id',
    element: <MediaContent/>
  },
  {
    path: '/media_content_video',
    element: <MediaContentList/>
  },
  {
    path: '/blog_content_detail/:id',
    element: <BlogDetail/>
  },
  {
    path: '/blog_content_list',
    element: <BlogList/>
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/contact_us',
    element: <ContactUs />,
  },
  {
    path: '/faq',
    element: <FAQ />,
  },
  
      {
        path: 'media_content_list',
        element:<ContentMediaList/>
      },
   

  {
    path: '/courses',
    element:<Homepage />

  },

  {
    path: '*',
    element: <ErrorNotFoundPage />,
  },
]);

function App() {
  return (
    <>
    
      <Toasts />
      <div className='w-full h-full bg-custom_black-1200'>
      <RouterProvider router={router} />
      </div>
    
      
      
    </>
  );
}

export default App;
