import { FaEllipsisV, FaRedo, FaRegSquare } from "react-icons/fa";
import CustomCard from "../custom-card/CustomCard";
import { BsClockHistory } from "react-icons/bs";
import { BiCheckCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
import useLoading from "../loading/LoadingHook";
import { Content } from "../../types/Course";
import { useMediaContentService } from "./MediaContentService";
import { MediaContent } from "../../types/MediaContent";
import ContentMediaAdd from "./MediaContentAdd";
import { Link } from "react-router-dom";

function ContentMediaList() {
  const [question, setQuestion] = useState<JSX.Element>()
  const [contents, setContents] = useState<Content[]>([])
  const loading = useLoading()

  const [mediaContent, setMediaContent]= useState<MediaContent[]>([])
  useEffect(()=>searchContent(),[question])
  const appendContent = (content: Content):void =>{
    setContents([...contents, content])
  }
  const searchContent = () => {
    loading.startLoading()
  }      

  const mediaMediaContent = useMediaContentService();
  const [cursor, setCursor] = useState("")
  const  [category_id, setCategoryId] = useState<any>(null)

  useEffect( () => 
      {
          loading.startLoading()
          mediaMediaContent.getMediaContents(category_id).then(
          ({data: courses}) => {
            setMediaContent(courses.results)
              loading.stopLoading()
          }
      ).catch(error => loading.stopLoading())
      
    }
      , [category_id, cursor])

  return (
    <>
      <div className="w-full pl-4 pt-20">
        <div className="flex gap-4 py-3 text-gray-500">
          <i>
            <FaRegSquare />
          </i>
          <button  onClick={()=>searchContent()} >
            <i>
              <FaRedo />
            </i>
          </button>
          <i>
            <FaEllipsisV />
          </i>
        </div>
        <div className="flex w-full">
          <div className="w-2/3 flex flex-col gap-4">
            {mediaContent.map(content=>
             <CustomCard>
              <div className="mr-5  mb-10 lg:mb-0">
                                        <Link to={`/course_add/${content.id}`}>
                                            <img className="rounded w-20 h-20" src={content.thumbnail_image as string} alt="" />
                                            </Link>
                                        </div>
             <h1 className="text-gray-500 mb-2 font-medium">{content.title}</h1>
             <div className="flex justify-between">
               {/* <p>{content.description}</p> */}
               <div className="flex">
                   <p className="flex items-center mr-4 gap-1"><i className="text-custom_green-800"><BsClockHistory /></i><span className="text-gray-500">1:00 hr</span></p>
                   <p className="flex items-center gap-1"><i className="text-custom_green-800"><BiCheckCircle /></i><span className="text-gray-500">5 Lessons</span></p>
               </div>
             </div>
           </CustomCard>
            )}
              {question}
          </div>
          <div className="w-1/3 pl-4">
           <ContentMediaAdd  />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentMediaList;
