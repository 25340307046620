
import { MenuItem } from "../../types/MenuItems";
import { checkIfAnyExist } from "../../utils/array";
import useTranslation from '../../utils/translation'
import useAuthorization from "../auth/authorization";

const useMenuService = () => {

    const {translate} = useTranslation()

    const menuList: MenuItem[] = [
        {
            id: 'home',
            name: translate('home'),
            link: "/",
            visible: false,
        },{
            id: 'media_content',
            name: translate('media_videos'),
            link: '/media_content_video',
            visible: false
        },
        {
            id: 'blog',
            name: translate('blog'),
            link: "/blog_content_list",
            visible: false,
        },

        {
            id: 'faq',
            name: translate("faq"),
            link: '/faq',
            visible: false
        },
        
        {
            id: 'about',
            name: translate("about"),
            link: '/about',
            visible: false
        },{
            id: 'contact_us',
            name: translate("contact_us"),
            link: '/contact_us',
            visible: false
        },
    ];
    menuList.forEach(menu => {
        menu.visible = menu.children && checkIfAnyExist(menu.children, 'visible', true)
    })

    return {menuList}
}

export default useMenuService
