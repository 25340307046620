import useApi from '../../utils/api';
import { Content, ContentSideNav, PaginatedData, Question } from '../../types/Course';
import { Blog } from '../../types/Blog';
import { title } from 'process';

export const useBlogService = () => {
    const {appApi} = useApi()

 const addBlog = (content: Content) => {
    const response = appApi.post<Blog>(
        'media_content/', content,
    );
    return response;
};

const getBlog = (id: string) => {
  const response = appApi.get<Blog>(`blog_content/${id}`);
  return response;
};

 const getBlogs = ({category_id, filterText, limit}:{category_id: string,filterText:string, limit:number}) => {
    const response = appApi.get<PaginatedData<Blog[]>>(
        'blog_content/',
        {
          params: {
            category_id,
            search:filterText,
            limit:limit,
            offset:0
          }
        }
    );
    return response;
};

const getAllBlogs = () => {
    const response = appApi.get<Blog[]>('blog_content/');
    return response;
};

const deleteBlog = (id: string) => {
  const response = appApi.delete<Blog>(`blog_content/${id}/`);
  return response;
};


return {addBlog, getBlog, getBlogs, deleteBlog, getAllBlogs}
}
