import React, { useEffect, useState } from 'react'
import useReviewerService from './ReviewerService';
import useLoading from '../loading/LoadingHook';
import { Content, Reviewer } from '../../types/Course';
import { BackEndError } from '../../types/UserItem';
type propsType = {
    id?:string;
    setOpenModel:(toggle:boolean)=>void,
    review:Reviewer,
    isEdit?:boolean,
    setIsEdit:(val:boolean)=>void,
    searchReviewers:()=>void,
}
const ReviewAdd = (props:propsType) => {
    const[rating,setRating] = useState(100);
    const [comment , setComment] = useState("");
    const reviewerService = useReviewerService();
    const [errors, setErrors] = useState<BackEndError<Reviewer>>();
    const loading = useLoading()

useEffect(()=>{
    if(props.review.comment !=""){
        setRating(props.review.rating);
        setComment(props.review.comment)
    }
  
  },[props.review.comment])
  const onSubmit = (e: any) => {
    e.preventDefault();
   
    if(props.isEdit){
        reviewerService.editReview({comment:comment,rating:rating},props.review.id?props.review.id:"").then(data=>{
            loading.stopLoading();
            props.setOpenModel(false)
            props.searchReviewers()
            props.setIsEdit(false);
        }).catch((error) =>  {setErrors(error.response.data.error.details); loading.stopLoading()});    
       } 
    else{  
        const data = {
            "rating":rating,
            "comment":comment,
            "target_id":props.id?props.id:"",
        }
    reviewerService.addReviewer(data).then(data=>{
        loading.stopLoading();
        props.setOpenModel(false)
        props.searchReviewers()
    }).catch((error) =>  {setErrors(error.response.data.error.details); loading.stopLoading()});    
   } 
  };

  const onUpdate = (e: any) => {
    e.preventDefault();
    
    reviewerService.editReview({comment:comment,rating:rating},props.review.id?props.review.id:'').then(data=>{
        loading.stopLoading();
        props.setOpenModel(false)
    }).catch((error) =>  {setErrors(error.response.data.error.details); loading.stopLoading()});    
  };

  

  return (<>
      {/* <!-- Reviews Form Modal Start --> */}
      <div
        className="relative w-full  max-w-2xl md:h-auto"
        id="reviewsModal"
        aria-hidden="true"
      >
        <div className="relative bg-white  rounded-lg shadow dark:bg-gray-700">
          <div className="relative flex flex-col w-full outline-0 border-2 border-custom_green-700">
              <div className="py-3 px-4 flex items-center justify-between border-b-2 border-custom_green-700">
                {!props.isEdit?(
                    <h5 className="text-2xl font-medium font-sans text-custom_black-200 mb-0">Add a Review</h5>
                ):(
                    <h5 className="text-2xl font-medium font-sans text-custom_black-200 mb-0">Edit a Review</h5>
                 
                )}
                  <button type="button" className="text-custom_green-900 hover:scale-150" data-bs-dismiss="modal" aria-label="Close" onClick={()=>props.setOpenModel(false)}>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
   
                  </button>
              </div>

              {/* <!-- Reviews Form Start --> */}
              <div className="pt-0 relative flex-1">
                  <form onSubmit={onSubmit}>
                      <div className="flex flex-wrap m-4 gap-x-4 max-w-full">

                          <div className="w-full ">
                              {/* <!-- Single Form Start --> */}
                              <div className="mt-4 ">
                                  <label className='block text-sm font-no
                                   font-sans text-custom_black-300 mb-0  '>Rating</label>
                                  <ul className="flex mt-2">
                                    <li onMouseEnter={()=>setRating(100)} className="cursor-pointer">
                                    <svg aria-hidden="true" className={` w-5 h-5 ${rating>=100?'text-yellow-400':'text-custom_black-900'} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                            
                                    </li>
                                    <li onMouseEnter={()=>setRating(101)} className="cursor-pointer">
                                    <svg aria-hidden="true" className={` w-5 h-5 ${rating>=101?'text-yellow-400':'text-custom_black-900'} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                            
                                    </li>
                                    <li onMouseEnter={()=>setRating(102)} className="cursor-pointer">
                                    <svg aria-hidden="true" className={` w-5 h-5 ${rating>=102?'text-yellow-400':'text-custom_black-900'} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                            
                                    </li>
                                    <li onMouseEnter={()=>setRating(103)} className="cursor-pointer">
                                    <svg aria-hidden="true" className={` w-5 h-5 ${rating>=103?'text-yellow-400':'text-custom_black-900'} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                            
                                    </li>
                                    <li onMouseEnter={()=>setRating(104)} className="cursor-pointer">
                                    <svg aria-hidden="true" className={` w-5 h-5 ${rating>=104?'text-yellow-400':'text-custom_black-900'} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                            
                                    </li>
                                    </ul>
                              </div>
                              {/* <!-- Single Form End --> */}
                          </div>
                          <div className="w-full">
                              {/* <!-- Single Form Start --> */}
                              <div className="mt-5">
                                  <textarea placeholder="Write your comments here" onChange={(event)=>setComment(event.target.value)} className='pt-4 h-40 
                                  resize-none w-full px-6 text-custom_black-200 
                                  border-2 border-custom_green-700 outline-2 outline-custom_green-800
                                  transition ease-in-out duration-1000 text-lg rounded-lg' value={comment}>
                                  
                                  </textarea>
                              </div>
                              {/* <!-- Single Form End --> */}
                          </div>
                          <div className="w-full">
                              {/* <!-- Single Form Start --> */}
                              {props.isEdit?(
                                 <div className="mt-5">
                                 <button className="relative z-10 text-white
                bg-custom_green-900 px-7 py-5 rounded-xl hover:scale-105">Edit Review</button>
                             </div>
                              ):(
                                <div className="mt-5">
                                <button className="relative z-10 text-white
               bg-custom_green-900 px-7 py-5 rounded-xl hover:scale-105">Submit Review</button>
                            </div>
                              )}
                             
                             
                              {/* <!-- Single Form End --> */}
                          </div>
                      </div>
                  </form>
              </div>
              {/* <!-- Reviews Form End --> */}
          </div>
        </div>
      </div>

  {/* <!-- Reviews Form Modal End --> */}

  </>)
}

export default ReviewAdd;
