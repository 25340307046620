import useApi from '../../utils/api';
import { Content, ContentSideNav, PaginatedData, Question } from '../../types/Course';
import { MediaContent } from '../../types/MediaContent';

export const useMediaContentService = () => {
    const {appApi} = useApi()

 const addMediaContent = (content: Content) => {
    const response = appApi.post<MediaContent>(
        'media_content/', content,
      //   {headers: {
      //     'content-type': 'application/json'
      // }}
    );
    return response;
};

const getMediaContent = (id: string) => {
  const response = appApi.get<MediaContent>(`media_content/${id}`);
  return response;
};

 const getMediaContents = ({category_id,content_type, filterText, limit}:{category_id: string,content_type:number, filterText:string, limit:number}) => {
    const response = appApi.get<PaginatedData<MediaContent[]>>(
        'media_content/',
        {
          params: {
            category_id,
            search:filterText,
            limit,
            offset:0,
            content_type,
          }
        }
    );
    return response;
};

const getAllMediaContents = () => {
    const response = appApi.get<MediaContent[]>('media_content/');
    return response;
};

const deleteMediaContent = (id: string) => {
  const response = appApi.delete<MediaContent>(`media_content/${id}/`);
  return response;
};


return {addMediaContent, getMediaContent, getMediaContents, deleteMediaContent, getAllMediaContents}
}
