export const dateTimeFormatter = (date?:string)=>{
    const dateTime = date?.split('T');
    var trimmedDatetime = ""
    const regx = /\.\d+\w+/

    if (dateTime !== undefined){
        const [datePart, timePart] = dateTime as string[]
        console.log(datePart, timePart)
      
        const [year, month, day] = datePart.split("-");
        var dateObject = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
        var dayNumber = dateObject.getDay();
        var monthNumber = dateObject.getMonth()
        var yearNumber = dateObject.getFullYear();
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = dayNames[dayNumber]
        const trimmedTimePart = timePart.substring(0, timePart.length - 4);
        trimmedDatetime = ` ( ${trimmedTimePart}), ${dayName} ${monthNumber}-${yearNumber}`;
    }

  return trimmedDatetime.replace(regx, '')
}