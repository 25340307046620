import Advert from "../advert/Advert";
import Navbar from "../navbar/Navbar";
import shape8 from "../../assets/images/shape-8.webp";
import shape23 from "../../assets/images/shape-23.webp";
import shape5 from "../../assets/images/shape-5.webp";
import shape6 from "../../assets/images/shape-6.webp";
import shape24 from "../../assets/images/shape-24.webp";
import author11 from "../../assets/images/author-11.webp";
import BreadCrumb from "../bread-crumb/BreadCrumb";
import { GiAchievement } from "react-icons/gi";
import CustomText from "../custom-text/CustomText";
import Navbar2 from "../navbar/Navbar2";

function Header(props: any) {
  const { title, activeTab, underlined = "Form" } = props;
  return (
    <>
      <div className="w-full z-50 overflow-hidden">
        <Advert />
        <Navbar2 />
      </div>
     
    </>
  );
}

export default Header;
