import Footer from "../components/footer/Footer";
import { FaClock,FaComment } from "react-icons/fa"
import { useEffect, useState } from "react";
import useLoading from "../components/loading/LoadingHook";
import {Blog} from '../types/Blog'

import useCategoryService from "../components/category/CategoryService";
import { FetchData } from "../types/FetchData";
import Header from "../components/header/Header";
import { useBlogService } from "../components/blog/blogService";
import useTranslation from "../utils/translation";
import { Link } from "react-router-dom";
import { MediaContent } from "../types/MediaContent";
import { useMediaContentService } from "../components/media-content/MediaContentService";
import { formatDistanceToNow } from 'date-fns';
import { DatePicker, Spin } from "antd";


function Home() {
   const [sticky, setSticky] = useState("");
   const [feature, setFeature] = useState<Blog[]>([])
  
   const loading = useLoading()
   const [id, setId] = useState('');
   const [count, setCount] = useState(0);
   const { translate } = useTranslation();
   const blogContent = useBlogService();
   const categoryService = useCategoryService();
 
   const [cursor, setCursor] = useState("")
   const [filterText, setFilterText] = useState("")
   const [category_id, setCategoryId] = useState<any>(null)

   const [mediaContent, setMediaContent] = useState<MediaContent[]>([])

   const mediaMediaContent = useMediaContentService();
   const [content_type, setContentType] = useState<number>(0);
  

    

      useEffect(() => {
         const handleScroll = () => {
           const offset = window.scrollY;
         console.log(offset)
           if (offset < 450) {
            setSticky("");
             
           } else if(offset< 1581) {
            setSticky("md:fixed md:bottom-0 md:z-40 max-w-[350px] right-5 xl:right-28 ");
           }else{
            setSticky("");
           }
         };
       
         window.addEventListener("scroll", handleScroll);
       
         return () => {
           window.removeEventListener("scroll", handleScroll);
         };
       }, []);

       useEffect(() => {
        loading.startLoading()
        searchBlogs();
        searchMediaContent()
      
    }
        , [])
       const searchBlogs = () => {
         blogContent.getBlogs({ category_id, filterText, limit: 10 }).then(
             ({ data: courses }) => {
               setFeature(courses.results)
                 loading.stopLoading()
             }
         ).catch(error => loading.stopLoading())
     }

     const searchMediaContent = () => {
      mediaMediaContent.getMediaContents({ category_id, content_type, filterText, limit: mediaContent ? mediaContent.length! + 5 : 5 }).then(
          ({ data: content }) => {
              setMediaContent(content.results)
              console.log(loading)
              loading.stopLoading()
          }
      ).catch(error => loading.stopLoading())
  }

  const timeFormater=(time:string="2023-12-30T09:26:36.420674Z")=>{
    return formatDistanceToNow(new Date(time), { addSuffix: true }); 
}  
    return (
        <div>
            <Header/>
            
               <div className="w-full min-h-screen bg-custom_black-1200 my-36 md:my-60 xl:px-24 flex  flex-col md:flex-row  md:justify-center">
                  {loading.loading?(
                    <div>
                       <Spin size="large" />
                    </div>
                  ):(
                    <>
                     <div className="flex max-w-[1200px] o flex-col mr-3 xl:mr-10  w-full">
                       <div className="flex justify-center w-full flex-col lg:flex-row   ">
                            <div className="flex flex-col lg:flex-3">
                              
                            <Link to={`/blog_content_detail/${feature[0]?.id}`} className="mr-2  mb-2 w-full md:min-w-[500px] relative cursor-pointer " >
                                  <img 
                                  src={feature[0]?.url}
                                   alt=""  />
                                   <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20 z-20 hover:opacity-0"></div>
                                     <h3 className="absolute left-0 top-0 px-3 lg:px-5 py-3 text-xl  text-white bg-custom_green-100">{feature[0]?.category_name}</h3>
                                   <div className="absolute bottom-5 left-10 ">
                                    <h1 className="text-xl lg:text-3xl  text-white font-semibold">{feature[0]?.title}</h1>
                                    <div className="flex ">
                                    <span className="text-white text-xl  flex mr-10 items-center"><FaClock className=""/>  { feature.length>0 && timeFormater(feature[0]?.create_date)}</span>
                                    <span className="text-white text-xl  flex items-center"><FaComment className="text-lg mr-5"/>10</span>
                                    </div>
                                    
                                   </div>
                                </Link>
                                <div className="flex flex-col lg:flex-row">
                                <Link to={`/blog_content_detail/${feature[1]?.id}`} className="mr-2 relative  overflow-hidden  max-h-56 mb-2 lg:flex-1 lg:mb-0 cursor-pointer">
                                    <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20 z-20 hover:opacity-0"></div>
                                    <img  
                                    src={feature[1]?.url} 
                                    alt="" className="w-full h-full " />
                                    <h3 className="absolute left-0 top-0 px-2  py-3 text-sm  text-white bg-custom_green-100">{feature[1]?.category_name}</h3>
                                   <div className="absolute bottom-5 left-2 z-5">
                                    <h1 className="text-sm  text-white font-semibold">{feature[1]?.title}</h1>
                                    <div className="flex ">
                                    <span className="text-white text-sm flex mr-10 items-center"><FaClock className=""/>  { feature.length>=1 && timeFormater(feature[1]?.create_date)}</span>
                                    <span className="text-white text-sm flex items-center"><FaComment className="text-sm mr-5"/>0</span>
                                    </div>
                                    </div>
                                    </Link>
                                   
                                    <Link to={`/blog_content_detail/${feature[2]?.id}`} className="mr-2 relative  overflow-hidden  max-h-56 mb-2 lg:flex-1 lg:mb-0 cursor-pointer">
                                    <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20 z-20 hover:opacity-0"></div>
                                    <img  
                                    src={feature[2]?.url} 
                                    alt="" className="w-full h-full " />
                                    <h3 className="absolute left-0 top-0  py-3 px-2 text-sm  text-white bg-custom_green-100">{feature[2]?.category_name}</h3>
                                   <div className="absolute bottom-5 left-2 z-5">
                                    <h1 className="text-sm  text-white font-semibold">{feature[2]?.title}</h1>
                                    <div className="flex ">
                                    <span className="text-white text-sm flex mr-10 items-center"><FaClock className=""/>  { feature.length>=2 && timeFormater(feature[2]?.create_date)}</span>
                                    <span className="text-white text-sm flex items-center"><FaComment className="text-sm mr-5"/>0</span>
                                    </div>
                                    </div>
                                    </Link>
                                </div>
                               
                            </div>
                            <div className="mr-2 ml-2 flex-col lg:flex-row lg:flex-1">
                            <Link to={`/media_content_detail/${mediaContent[0]?.id}`} className="mb-2 max-h-64  overflow-hidden relative  md:max-h-80">
                                 <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20 z-20 hover:opacity-0"></div>
                                   
                                 <img className=" min-w-[250px] w-full mb-2" src={mediaContent[0]?.thumbnail_image} alt="" />
                                 <h3 className="absolute left-0 top-0  py-3 px-3 text-lg text-white bg-custom_green-100">{mediaContent[0]?.category_name}</h3>
                                   <div className="absolute bottom-1 left-2">
                                    <h1 className="text-sm  text-white font-semibold">{mediaContent[0]?.title}</h1>
                                    <div className="flex ">
                                    <span className="text-white text-sm flex mr-10 items-center"><FaClock className=""/>  { mediaContent.length>0 && timeFormater(mediaContent[0]?.create_date)}</span>
                                    <span className="text-white text-sm flex items-center"><FaComment className="text-sm mr-5"/>0</span>
                                    </div>
                                    </div>
                                 </Link>
                                 <Link to={`/media_content_detail/${mediaContent[1]?.id}`} className="mb-2 max-h-64  overflow-hidden relative  md:max-h-80">
                                 <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20 z-20 hover:opacity-0"></div>
                                   
                                 <img className=" min-w-[250px] mb-2 w-full" src={mediaContent[1]?.thumbnail_image} alt="" />
                                 <h3 className="absolute left-0 top-0  py-3 px-3 text-sm text-white bg-custom_green-100">{mediaContent[1]?.category_name}</h3>
                                   <div className="absolute bottom-1 left-2">
                                    <h1 className="text-sm  text-white font-semibold">{mediaContent[1]?.title}</h1>
                                    <div className="flex ">
                                    <span className="text-white text-sm flex mr-10 items-center"><FaClock className=""/>  { mediaContent.length>=1 && timeFormater(mediaContent[1]?.create_date)}</span>
                                    <span className="text-white text-sm flex items-center"><FaComment className="text-sm mr-5"/>0</span>
                                    </div>
                                    </div>
                                 </Link>
                                 <Link to={`/media_content_detail/${mediaContent[2]?.id}`} className="mb-2 max-h-64  overflow-hidden relative  md:max-h-80">
                                 <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20 z-20 hover:opacity-0"></div>
                                   
                                 <img className=" min-w-[250px] mb-2 w-full" src={mediaContent[2]?.thumbnail_image} alt="" />
                                 <h3 className="absolute left-0 top-0  py-3 px-3 text-sm text-white bg-custom_green-100">{mediaContent[2]?.category_name}</h3>
                                   <div className="absolute bottom-1 left-2">
                                    <h1 className="text-sm  text-white font-semibold">{mediaContent[2]?.title}</h1>
                                    <div className="flex ">
                                    <span className="text-white text-sm flex mr-10 items-center"><FaClock className=""/>  { mediaContent.length>=2 && timeFormater(mediaContent[2]?.create_date)}</span>
                                    <span className="text-white text-sm flex items-center"><FaComment className="text-sm mr-5"/>0</span>
                                    </div>
                                    </div>
                                 </Link>
                            </div>
                       </div>
                       <div className="flex flex-col md:flex-row">
                            <div className="mr-3 md:flex-1 ">
                            <h1 className="w-full border-b-8 border-custom_green-100 text-xl mb-3">Latest</h1>
                       {mediaContent.map(item=>(
                         <Link to={`/media_content_detail/${item.id}`} className=" w-full rounded-lg flex flex-col mb-3  bg-white  xl:flex-row lg:mr-10 xl:items-center xl:justify-between">
                            <div className="mb-2 relative w-full lg:w-52 gradient-overlay xl:flex-1">
                                <img className="w-full lg:h-full" src={item.thumbnail_image} alt="" />
                                <h3 className="absolute left-0 top-0 px-2  xl:hidden py-1 text-lg text-white bg-custom_green-100">{item.category_name}</h3>
                                 
                            </div>
                            <div className="ml-3 xl:flex-1"> 
                            <h3 className=" hidden xl:block  px-3 py-1 text-sm text-white bg-custom_green-100">{item.category_name}</h3>
                               
                                <h3 className="text-sm">{item.title}</h3>
                                {/* <h3 className="max-w-[400px] text-lg text-custom_black-500">{item.subject}</h3> */}
                                <div className="flex ">
                                    <span className="text-custom_black-500 text-sm flex mr-10 items-center"><FaClock className=""/>  {timeFormater(item.create_date)}</span>
                                    <span className="text-custom_black-500  text-sm flex items-center"><FaComment className="text-xl mr-5"/>0</span>
                                    </div>
                            </div>
                        </Link>
                       ))}
                            </div>
                            <div className=" md:flex-1"> 
                            <h1 className="w-full border-b-8 border-custom_green-100 text-xl mb-3">Popular</h1>
                       <h2>coming Soon</h2>
                            </div>
                       </div>
                   </div>
                   <div  className={`xl:min-w-[350px] relative  h-full`}>
                     <div className={`xl:min-w-[350px] `}>
                     <h1 className="w-full border-b-8 border-custom_green-100 text-xl mb-3">Blog</h1>
                       {feature.map(item=>(
                          <Link to={`/blog_content_detail/${item.id}`} className=" w-full rounded-lg flex flex-col mb-3   xl:flex-row lg:mr-10 xl:items-center  xl:justify-between bg-white  lg:pr-3">
                            <div className="mb-2 relative  w-full lg:w-52 xl:min-w-72 2xl:min-w-80 xl:flex-1">
                                <img className="w-full lg:h-full" src={item.url} alt="" />
                                <h3 className="absolute left-0 top-0 px-2  xl:hidden py-1 text-lg text-white bg-custom_green-100">{item.category_name}</h3>
                                 
                            </div>
                            <div className="ml-3 xl:flex-1"> 
                            <h3 className=" hidden xl:block  px-3 py-1 text-lg text-white bg-custom_green-100">{item.category_name}</h3>
                               
                                <h3 className="text-sm">{item.title}</h3>
                                {/* <h3 className="text-lg text-custom_black-500">{item.description.slice(0,100)}</h3> */}
                                <div className="flex ">
                                    <span className="text-custom_black-500 text-sm flex mr-10 items-center"><FaClock className=""/>   {timeFormater(item.create_date)}</span>
                                    <span className="text-custom_black-500  text-sm flex items-center"><FaComment className="text-xl mr-5"/>0</span>
                                    </div>
                            </div>
                        </Link>
                       ))}
                     </div>
                    
                   </div>
                    </>
                  )

                  }
                  
               </div>

<Footer />

                      

        </div>
    );
}

export default Home;
