import { Spin } from "antd"

const Loading2 = (props: {text: string, loading: boolean}) => {
return props.loading ? <>
<div className="w-full h-full flex items-center justify-center">
<Spin size="large" />
</div>
</> : <></>
}

export default Loading2