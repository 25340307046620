import Footer from "../components/footer/Footer";
import { FaStar, FaSearch, FaShare, FaPlus, FaArchive, FaAngleRight, FaAngleLeft, FaClock, FaBookOpen } from "react-icons/fa"
import { MenuItem } from "../types/MenuItems";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoading from "../components/loading/LoadingHook";
import { useMediaContentService } from "../components/media-content/MediaContentService";
import { MediaContent } from "../types/MediaContent";
import Advert from "../components/advert/Advert";
import Navbar from "../components/navbar/Navbar";
import Navbar2 from "../components/navbar/Navbar2";
import slider1 from "../assets/images/slider-1.png";
import shape12 from "../assets/images/shape-12.webp";
import shape13 from "../assets/images/shape-13.svg";
import useCategoryService from "../components/category/CategoryService";
import { FetchData } from "../types/FetchData";
import { Category } from "../types/Category";
import { dateTimeFormatter } from "../utils/dateUtils";
import { ContentType, ContentTypeObject } from "../types/Enums";
import { formatDistanceToNow } from 'date-fns';
import Header from "../components/header/Header";
import { Spin } from "antd";
import Loading from "../components/loading/Loading";
import Loading2 from "../components/loading/Loading2";

export const options: MenuItem[] = [
    {
        id: 'share',
        name: 'Share',
        link: "/",
        icon: <FaShare />
    },
    {
        id: 'creat_collection',
        name: 'Create Collection',
        link: "/",
        icon: <FaPlus />

    },
    {
        id: 'favourite',
        name: 'Favourite',
        link: "/",
        icon: <FaStar />
    },
    {
        id: 'archive',
        name: 'Archive',
        link: "/",
        icon: <FaArchive />
    }
];




function MediaContentList() {
    const [mediaContent, setMediaContent] = useState<MediaContent[]>([])
    const [categories, setCategories] = useState<FetchData<Category>>();
    const loading = useLoading()
    const [id, setId] = useState('');
    const [count, setCount] = useState(0);
    const mediaMediaContent = useMediaContentService();
    const categoryService = useCategoryService();
    const [cursor, setCursor] = useState("")
    const [filterText, setFilterText] = useState("")
    const [category_id, setCategoryId] = useState<any>(null)
    const [content_type, setContentType] = useState<number>(0);
    const [item_count, setItemCount] = useState<number>(0)

    useEffect(() => {
        loading.startLoading()
        searchMediaContent()
        searchCategory();
    }
        , [category_id, content_type, cursor, filterText])

    const searchCategory = () => {
        categoryService.getCategories({ pageSize: 4, cursor: cursor, searchText: "" }).then(({ data: res }) => {
            setCategories(res)
        })
    }

    const handleClick = (e: any) => {
        e.preventDefault();
        if (count === 1) {
            hanldeClose(e); setCount(0)
        } else { setId(e.target.id); setCount(1); }
    }

    const hanldeClose = (e: any) => {
        setId('');
    }

    const searchMediaContent = () => {
        mediaMediaContent.getMediaContents({ category_id, content_type, filterText, limit: mediaContent ? mediaContent.length! + 4 : 4 }).then(
            ({ data: content }) => {
                setMediaContent(content.results)
                setItemCount(content.count)
                loading.stopLoading()
            }
        ).catch(error => loading.stopLoading())
    }

    const timeFormater=(time:string)=>{
        
        return formatDistanceToNow(new Date(time), { addSuffix: true });

      
    }

    return (
        <>
            <Header/>
            
            <div className="bg-custom_black-1200 pt-8 px-3 my-36 md:my-60">
                <div className="w-full flex  flex-col md:flex-col items-center md:items-start">
                 <div className="flex flex-col md:flex-row mx-16 md:mx-auto">
                     <div className="flex mt-3 md:mt-0  md:ml-0 flex-col md:flex-row  md:justify-between md:place-items-center gap-y-2">
                            <div className="relative max-w-[300px] flex justify-end  w-full">
                                <form action="#">
                                    <input type="text" name="" placeholder="Search Your media videos" id="" value={filterText} onChange={(e) => setFilterText(e.target.value)} className="w-full h-[50px] rounded-[50px] border-[1px] border-[solid] border-[rgba(48,146,85,0.2)] py-0 px-[30px] pr-[90px] outline-none transition-[all 0.3s ease-in-out 0s] " />
                                    <button className="flex justify-center place-items-center absolute w-[50px] h-[50px] leading-[54px] text-center rounded-r-[50px] bg-[#deede4] border-0 top-[1px] right-[1px] text-[16px] text-custom_green-900">
                                        <FaSearch />
                                    </button>
                                </form>
                            </div>
                        </div>
                    <div className="touch-pan-y mx-10 relative overflow-hidden list-none p-0 z-[1]">
                                <ul className="relative w-full h-full z-[1] transition-transform box-content p-0 m-0 flex flex-wrap justify-center items-center pl-0 mb-0 list-none transform-[translate3d(0,0,0)] ">
                                    {ContentTypeObject?.map((content, index) => (
                                        <li className="flex-shrink-0  relative transition-transform mt-4 mr-2 w-[250px]">
                                            <button
                                                className={`${content_type === content.value ? 'border-custom_green-900' : ''
                                                    } w-full min-h-[60px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-[15px] font-medium transition-[all 0.3s ease-in-out 0s] px-[5px] py-0  text-overflow-ellipsis active:border-custom_green-900 active:text-custom_green-900 hover:border-custom_green-900 hover:text-custom_green-900`}
                                    onClick={() => content_type && content_type === content.value ? setContentType(ContentType.VIDEO.valueOf()) : setContentType(content.value)}

                                            >
                                                <h2>{content.content_type} </h2>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <span className="absolute left-0 top-0 pointer-events-none opacity-0 z-[-1000] list-none"></span>
                            </div>
                       
                </div>
                <div className="container mx-auto">
                    <div className="flex mt-3 md:mt-0  md:ml-0 flex-col md:flex-row  md:justify-between md:place-items-center gap-y-2">
                        {/*<div className="hidden md:block">
                            <h2 className="text-[20px] font-medium mb-0 leading-[1.4]">All
                                <span className="mx-2  relative text-custom_green-900 before:absolute before:bg-shape_11 before:bg-center before:bg-cover 
    before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2"> video's
                                </span>                                    of Tunbi Media
                            </h2>
                        </div>*/}
                        
                    </div>
                    <div className="py-[40px] px-6 rounded-[10px] mt-[50px] relative box-border mx-4">
                        <div className="touch-pan-y mx-10 relative overflow-hidden list-none p-0 z-[1]">
                            <ul className="relative w-full h-full z-[1] transition-transform box-content p-0 m-0 flex flex-wrap justify-center items-center pl-0 mb-0 list-none transform-[translate3d(0,0,0)] ">
                                {categories?.results.map((value, index) => (
                                    <li className="flex-shrink-0  relative transition-transform mt-4 mr-2 w-[250px]" key={value.id}>
                                        <button
                                            className={`${category_id === value.id ? 'border-custom_green-900' : ''
                                                } w-full min-h-[60px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-[15px] font-medium transition-[all 0.3s ease-in-out 0s] px-[5px] py-0  text-overflow-ellipsis active:border-custom_green-900 active:text-custom_green-900 hover:border-custom_green-900 hover:text-custom_green-900`}
                                            onClick={() => { 
                                                if (category_id == value.id )
                                                    setCategoryId(null)
                                                else 
                                                    setCategoryId(value.id)

                                            }}
                                        >
                                           <span> {value.category_name.substring(0, 30)} </span> 
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <span className="absolute left-0 top-0 pointer-events-none opacity-0 z-[-1000] list-none"></span>
                        </div>

                        <button className="after:content-[''] after:display-none  right-[10px] opacity-[1] w-[40px] h-[40px] leading-[40px] bg-white rounded-[50%] shadow-[0px 0px 20px 0px rgba(48,146,85,0.05)] text-[#212832] cursor-pointer outline-none transition-[all 0.3s ease-in-out 0s] left-auto absolute top-[35%] z-[10] flex place-items-center justify-center" onClick={() => {  }} ><FaAngleRight /></button>
                        <button className="after:content-[''] after:display-none  left-[10px] opacity-[1] w-[40px] h-[40px] leading-[40px] bg-white rounded-[50%] shadow-[0px 0px 20px 0px rgba(48,146,85,0.05)] text-[#212832] cursor-pointer outline-none transition-[all 0.3s ease-in-out 0s] left-auto absolute top-[35%] z-[10] flex place-items-center justify-center" onClick={() => {  }}><FaAngleLeft /></button>
                    </div>
                     

                    <div className="flex  flex-col md:flex-row flex-wrap justify-center">
                        {
                        loading.loading?(<Loading2 {...loading}/>
                            ): mediaContent?.map((media, index)=><>
                             <Link to={`/media_content_detail/${media?.id}`} className=" md:max-w-[300px] mb-5 flex flex-col md:mr-2  pb-5 rounded-lg border-2 border-gray-300 text-justify">
                             
                                <img className=" h-[250px] overflow-hidden" src={media?.thumbnail_image} alt="" />
                                <span className="text-gray-400 text-[13px] px-3 self-end justify-self-end my-2">Posted  {timeFormater(media.create_date)}</span>
                                <div className="px-3">
                                    <h3 className="text-sm font-medium">{media?.title.slice(0,50)}</h3>
                                    <p className="text-gray-600 text-sm">{media.description.slice(0,100)}
                                       <span className="text-custom_green-200">...read more</span> </p>
                                </div>
                            </Link>
                        </>)
                        }
                    </div>
                    <div className="pt-10 sm:pt-[80px] w-full p-5 sm:p-0">
                        <div className="pt-2 w-full flex justify-center">
                           
                        {
                                item_count !== mediaContent.length ?
                                <button type="button" onClick={searchMediaContent} className="relative  text-white
                        bg-custom_green-900 px-7 mb-28 py-3 sm:py-5 my-5 mx-10 sm:mx-96    rounded-xl hover:scale-105 z-20">Click More ...</button>
                                :""
                            }
                        </div>
                    </div>
                </div>

            </div>
            </div>
            <Footer />

        </>
    );
}

export default MediaContentList;
