export const ContentType = {
  VIDEO: 100,
  AUDIO: 101,
  IMAGE: 102,
  DOCUMENT: 103,
  YOUTUBE_VIDEO: 104
} as const

export interface ContentTypeI {
  content_type:string, 
  value:number
}

export const ContentTypeObject:ContentTypeI[] = [
  {content_type:"Video", value:100},
  {content_type:"Image", value: 102},
  {content_type:"Document", value:103},
 
]

export const ProgresStatus = {
  STARTED: 100,
  FINISHED: 102,
} as const


export  const FileTypes = {
  100:'.mp4,.avi,.Wmv,.mov,.Mkv',
  101:'.jpg,.jpeg,PNG,GIF,TIFF',
  102: '.pdf',
  103: '*',
  104: '*',
}