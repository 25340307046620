import { AboutUs } from '../../types/AboutUs';
import useApi from '../../utils/api';

export const useAboutUsService = () => {
    const {commonApi} = useApi()

 const getContactUs = () => {
    const response = commonApi.get<AboutUs[]>(
        'about_us/', {
            params: {}
        }
      
    );
    return response;
};

return {getContactUs}
}
