import useApi from "../../utils/api";
import { PaginatedData, Reviewer } from "../../types/Course";

const useReviewerService = () => {
  const { appApi } = useApi();
  const getContent = (id: string) => {
    const response = appApi.get<Reviewer>(`review/${id}/`);
    return response;
  };

  const addReviewer = (review: Reviewer) => {
    const response = appApi.post<Reviewer>("review/", 
      review,
      );
    return response;
  };

  const getReviewers = ({filterText,target_id,limit }: { filterText: string, target_id?:string,limit?:number }) => {
    const response = appApi.get<PaginatedData<Reviewer[]>>("review/", {
      params: {
        search: filterText,
        target_id: target_id,
        limit: limit,
        offset:0
      },
    });
    return response;
  };
  const editReview = (review:{comment:string,rating:number},id:string) => {
    const response = appApi.patch<Reviewer>(`review/${id}/`,review);
    return response;
  };

  const deleteReviewer = (id: string) => {
    const response = appApi.delete<Reviewer>(`review/${id}/`);
    return response;
  };

  return {getContent, getReviewers, addReviewer, deleteReviewer,editReview}
};

export default useReviewerService
