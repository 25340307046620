import { FaTimes } from "react-icons/fa";
import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineMail, AiOutlineSkype } from "react-icons/ai";
import MobileMenu from "../menu/MobileMenu";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";

function SideNav(props: any) {
    const { setShowSideNav } = props;
    return <div className="left-0 fixed top-0 w-80 h-full bg-white z-50 pt-16 overflow-auto">
        <a className="absolute top-5 right-6 text-3xl text-custom_black-200" onClick={() => setShowSideNav(false)}>
            <i className="text-lg"><FaTimes /></i>
        </a>
            <div className="pt-7 ">
            <p className="text-sm text-custom_black-200white font-normal mb-0 flex justify-center">
            <Link
                to="/"
                className="no-underline text-inherit flex place-items-center text-2xl"
              >
                <img src={logo} alt="Logo" className="w-14" />
                <span className="text-custom_dark_green">Tunbi Media</span>
              </Link>
            </p>
        </div>
        {/* <div className="pt-7">
            <ul className="flex items-center justify-center">
                <li className="mr-5">
                    <Link to="/login" className="text-sm font-medium capitalize text-custom_black-200 transition-all duration-300 ease-in block h-11 leading-10 px-7
                    border border-custom_green-900 rounded-md bg-white hover:bg-custom_green-900 hover:text-white">Sign In</Link>
                </li>
                <li>
                    <Link to="/register" className="text-sm font-medium capitalize text-custom_black-200 transition-all duration-300 ease-in block h-11 leading-10 px-7
                    border border-custom_green-900 rounded-md bg-white hover:bg-custom_green-900 hover:text-white">Sign Up</Link>
                </li>
            </ul>
        </div> */}
        <MobileMenu />
        <div className="pb-7">
            <ul className="flex justify-center">
                <li className="mr-5">
                    <a href="#" className="text-xl text-custom_black-200 hover:text-custom_green-900">
                        <i><FiFacebook /></i>
                    </a>
                </li>
                <li className="mr-5">
                    <a href="#" className="text-xl text-custom_black-200 hover:text-custom_green-900">
                        <i><FiTwitter /></i>
                    </a>
                </li>
                <li className="mr-5">
                    <a href="#" className="text-xl text-custom_black-200 hover:text-custom_green-900">
                        <i><AiOutlineSkype /></i>
                    </a>
                </li>
                <li className="mr-5">
                    <a href="#" className="text-xl text-custom_black-200 hover:text-custom_green-900">
                        <i><FiInstagram /></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>;
}

export default SideNav;