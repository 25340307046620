import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import about from '../assets/images/about.webp';
import CustomText from "../components/custom-text/CustomText";
import CustomButton from "../components/custom-button/CustomButton";
import CustomCard from "../components/custom-card/CustomCard";
import { FiUser } from 'react-icons/fi';
import shape12 from "../assets/images/shape-12.webp";
import course1 from "../../src/assets/images/courses-01.webp";
import { FaStar, FaQuoteLeft } from "react-icons/fa"
import data from '../components/carousel/data.json';
import useTranslation from "../utils/translation";
import useCarousel from "../components/carousel/carouselHook";
import { useEffect, useState } from "react";
import { AboutUs } from "../types/AboutUs";
import { useAboutUsService } from "../components/about-us/aboutUsService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Advert from "../components/advert/Advert";
import Navbar from "../components/navbar/Navbar";
import { Link } from "react-router-dom";
import Loading2 from "../components/loading/Loading2";
import useLoading from "../components/loading/LoadingHook";

function About() {
  const { translate } = useTranslation()
  const carousel = useCarousel(1)
  const aboutUsService = useAboutUsService()
  const [aboutCompany, setAboutCompay] = useState<AboutUs>()
  const loading = useLoading()

  useEffect(() => {
    carousel.updateChildren(data.resources, component)
    searchAboutUs()
  }, [carousel.width])

  const searchAboutUs = () => {
    loading.startLoading()
    aboutUsService.getContactUs().then(contactUs => {
      setAboutCompay(contactUs.data[0])
      loading.stopLoading()
    })
  }
  const component = (data: { title: string, link: string, imageUrl: string }) =>
  (<CustomCard key={data.title}>
    <div className="">
      <div className="relative ">
        <img
          src={course1}
          alt=""
          className="block w-[90px] h-[90px] border-[1px] border-solid border-[rgba(48,146,85,0.2)]
           p-[8px] my-0 mx-auto max-w-full rounded-[50%]"
        />
        <i className=" w-[30px] h-[30px] leading-[30px] text-center bg-custom_green-900 text-black text-[13px]
         rounded-[50%]  absolute bottom-[-13px] left-0 right-0 mx-auto my-0 flex justify-center place-items-center">
          {" "}
          <FaQuoteLeft />
        </i>
      </div>
      <span className="mt-[35px] text-[13px] font-700 text-[#ffba00] line-through mr-[5px] flex justify-center">
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar className="text-[#d0d0d0]" />
      </span>
    </div>
  </CustomCard>)

  const quillModules = {
    toolbar: false, // Set toolbar to false to remove it
  };
  const quillStyles = {
    border: 'none'
  }
  
  return (
    <>
      <Header/>
       <Loading2 {...loading}/>
                            
      <div className="pt-20  container p-5 sm:mx-auto grid grid-cols-1 lg:grid-cols-3 gap-5 my-36 md:my-60">
        <CustomCard >
          <div className="flex items-center">
            <div>
              <i className="w-20 h-20 leading-10 flex items-center justify-center 
              rounded-full bg-custom_green-100 text-custom_green-900 text-4xl  transition-all duration-300 ease-in">
                <FiUser size={20} style={{"color":"text-white"}}/>
              </i>
            </div>
            <div className="flex-1 pl-5">
              <h3 className="font-medium text-lg">Mission</h3>
            </div>
          </div>
          <p className="mt-6 text-custom_black-200 h-90">
            {aboutCompany?.mission}
          </p>
        </CustomCard>
        
        <CustomCard>
          <div className="flex items-center">
            <div>
              <i className="w-20 h-20 leading-10 flex items-center justify-center rounded-full bg-custom_green-100 text-custom_green-900 text-4xl transition-all duration-300 ease-in">
                <FiUser size={20} />
              </i>
            </div>
            <div className="flex-1 pl-5">
              <h3 className="font-medium text-lg">Vision</h3>
            </div>
          </div>
          <p className="mt-6 h-90">
            {aboutCompany?.vision}
          </p>

        </CustomCard>
        
        <CustomCard>
          <div className="flex items-center">
            <div>
              <i className="w-20 h-20 leading-10 flex items-center justify-center rounded-full bg-custom_green-100 text-custom_green-900 text-4xl transition-all duration-300 ease-in">
                <FiUser size={20} />
              </i>
            </div>
            <div className="flex-1 pl-5">
              <h3 className="font-medium text-lg">Goals</h3>
            </div>
          </div>
          <p className="mt-6 h-90">
            {aboutCompany?.goal}
          </p>

        </CustomCard>
      </div>
      <div className="relative p-5 sm:px-0 mx-20">
        <div className="bg-custom_green-100 rounded-xl  sm:rounded-0 p-10 sm:p-14 mb-20">
           
          <img
            src={shape12}
            alt="shape8"
            className="hidden sm:inline-block absolute top-7 right-7 animate-custom-spin"
          />
          <div className="flex flex-col sm:flex-row justify-between flex-wrap">
            <div className="max-w-md md:w-1/2">
              <h5 className="font-medium text-black mb-5 text-lg md:text-xl">
                Become A Supporter
              </h5>
              <h2 className="font-medium mb-0 text-4xl md:text-2xl">
                You can join with Tunbi as <span className="text-black">a Supporter and Infor Provider? </span>
              </h2>
            </div>
            <div className="w-full sm:w-1/2 flex-shrink-0 flex-grow-0 basis-auto max-w-full mt-[30px] ">
              <div className="text-white">
                <Link to={"/contact_us"} className="font-medium text-text border border-gray-300 bg-[#212832] text-[16px]  mt-[13px] inline-block 
                leading-[3.75rem] py-0 px-[2.188rem] text-[1.125rem] rounded-[10px] bg-custom_green-900 border-custom_green-900">Drop Information</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<DownloadSection />*/}

      <Footer />
    </>
  );
}

export default About;
