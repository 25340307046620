import { FieldValues } from "react-hook-form";
import { InputProps } from "../../types/InputTypeProp";
import CustomInputError from "../custom-input-error/CustomInputError";

function CustomInput<T extends FieldValues>({type = 'text', label, placeholder, register, options, error}: InputProps<T>) {

    return (<div className="mt-5">
        <input type={type} {...register(label, options)} placeholder={placeholder} className="w-full h-10 px-6 text-base text-custom_black-200 transition-all duration-300 ease-in border-solid focus:outline-custom_green-900 border-custom_green-100 border rounded-lg bg-white" />
        <CustomInputError msg={error?.message} />
    </div>);
}

export default CustomInput;