import { Player } from 'video-react';
import { ContentType } from '../types/Enums';
import ReactPlayer from 'react-player/youtube';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { useState } from 'react';
import { RiArrowLeftFill, RiArrowRightFill } from 'react-icons/ri';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const MediaContentType = ({
  contentType,
  url,
}: {
  contentType: number;
  url: string;
}) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1); 
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) {
    setNumPages(nextNumPages);
  }

  function onPageLoadSuccess() {
    setPageWidth(window.innerWidth);
    setLoading(false);
  }

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  // Go to next page
  function goToNextPage() {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  function goToPreviousPage() {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  switch (contentType) {
    case ContentType.VIDEO.valueOf():
      return (
        <div className="p-10">
          <Player
            playsInline
            poster="https://media.istockphoto.com/id/1340716614/photo/abstract-icon-representing-the-ecological-call-to-recycle-and-reuse-in-the-form-of-a-pond.jpg?s=1024x1024&w=is&k=20&c=qCgLki6nJ_PUS_4SEQ8Jwrot5BM4XPRUqMP8KkWjFH8="
            src={
              url ? url : 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
            }
            autoPlay
          />
        </div>
      );
    case ContentType.IMAGE.valueOf():
      return (
        <>
          <img src={url} className="mt-5 p-0 sm:p-10" alt="No Image file" width={800} height={200}/>
        </>
      );
    case ContentType.YOUTUBE_VIDEO.valueOf():
      return (
        <div className="">
          <ReactPlayer
            url={url}
            width={1100}
            height={600}
            className="p-10 pl-10"
          />
        </div>
      );
    case ContentType.DOCUMENT.valueOf():
      return (
        <>
      <Nav pageNumber={pageNumber} numPages={numPages} />
      <div
        hidden={loading}
        style={{ height: "calc(100vh - 64px)" }}
        className="flex items-center"
      >
        <div
          className={`flex items-center justify-between w-full absolute z-10 px-2`}
        >
          <button
            onClick={goToPreviousPage}
            disabled={pageNumber <= 1}
            className="relative h-[calc(100vh - 64px)] px-2 py-24 text-custom_green-800 focus:z-20"
          >
            <span className="sr-only">Previous</span>
            <RiArrowLeftFill className="h-10 w-10" aria-hidden="true" />
          </button>
          <button
            onClick={goToNextPage}
            disabled={pageNumber >= numPages!}
            className="relative h-[calc(100vh - 64px)] px-2 py-24 text-custom_green-800  focus:z-20"
          >
            <span className="sr-only">Next</span>
            <RiArrowRightFill className="h-10 w-10" aria-hidden="true" />
          </button>
        </div>

        <div className="h-full flex justify-center mx-auto">
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            renderMode="canvas"
            className=""
          >
            <Page
              className=""
              key={pageNumber}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              onLoadSuccess={onPageLoadSuccess}
              onRenderError={() => setLoading(false)}
              width={Math.max(pageWidth * 0.4, 390)}
            />
          </Document>
        </div>
      </div>
    </>
      );
    default:
      return <></>;
  }
};

export default MediaContentType;


function Nav({pageNumber, numPages}: {pageNumber: number, numPages: number}) {
  return (
    <nav className="bg-custom_green-800 ">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <p className="text-2xl font-bold tracking-tighter text-white">
                
              </p>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="bg-white text-custom_green-800 rounded-md px-3 py-2 text-sm font-medium">
              <span>{pageNumber}</span>
              <span className="text-gray-400"> / {numPages}</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}