import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation,Link } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import { Languages } from "../../types/Translation";
import { FaFacebook,FaTwitter,FaWifi,FaPlayCircle } from 'react-icons/fa'
import SideNav from "./SideNav";

function Navbar2() {
  const [showSideNav, setShowSideNav] = useState(false);
  
  const [sticky, setSticky] = useState("");
  // const { register, setError, handleSubmit, formState: { errors } } = useForm<Langu>();
  const [selectedLanguage, setSelectedLanguage] = useState<{
    name: string;
    id: Languages;
  }>();
 
  const location = useLocation();

  // on render, set listener
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
    
      // if (offset > 200) {
      //   setSticky("fixed w-full top-0 z-50");
      // } else {
      //   setSticky("");
      // }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
 

  return (
    <>
      <div className={`overflow-hidden fixed w-full top-0 z-50`}>
        <div className="relative">
            <div className=" bg-custom_green-400 lg:bg-white h-32 px-2 xl:px-40 py-2 xl:py-5 flex items-center justify-between " >
                <div className="flex items-center ">
                    <img src={logo} alt="Logo" className="w-20 lg:w-24 mr-5" />
                    <span className="text-black text-xl  italic">Tunbi Media</span>
                </div>
                
                <div className="flex items-center ">
                    <div className="flex mr-5">
                       <FaFacebook className="text-xl mr-6 text-custom_black-800 cursor-pointer hover:scale-110 hover:text-custom_green-400"/>
                       <FaTwitter className="text-xl mr-6 text-custom_black-800 cursor-pointer hover:scale-110 hover:text-custom_green-400 "/>
                       <FaWifi className="text-xl text-custom_black-800 cursor-pointer hover:scale-110 hover:text-custom_green-400 "/>
                    </div>
                    <div className="px-8 bg-custom_green-100  items-center cursor-pointer hidden lg:flex py-4">
                        <p className="text-xl text-white mr-4">
                            Online
                            
                        </p>
                        <FaPlayCircle className="text-3xl text-white"/>
                    </div>
                    <div className="lg:hidden">
              <Link
                to='#'
                className="inline-block p-0"
                onClick={() => setShowSideNav(!showSideNav)}
              >
                <span className="w-6 h-0.5 bg-custom_black-200 block"></span>
                <span className="w-6 h-0.5 bg-custom_black-200 block my-1"></span>
                <span className="w-6 h-0.5 bg-custom_black-200 block"></span>
              </Link>
            </div>
                </div>
            </div>
            <div className={`h-16 bg-custom_green-400 hidden lg:flex items-center justify-center w-full `}>
               <NavLink className={`${location.pathname==='/'?"bg-white text-black":'bg-custom_green-400 text-white'} h-full flex    items-center lg:px-8 border-l-2 text-sm
                border-r-1 border-white`} to={"/"}>
                  Home
               </NavLink>
               <NavLink 
              className={`${location.pathname==='/media_content_video'?"bg-white text-black":'bg-custom_green-400 text-white'} h-full flex    items-center lg:px-8 border-l-2 text-sm
              border-r-1 border-white`}
               to={"/media_content_video"}>
              
                  Vodcasts
               </NavLink>
               <NavLink className={`${location.pathname==='/blog_content_list'?"bg-white text-black":'bg-custom_green-400 text-white'} h-full flex    items-center px-8 border-l-2 text-sm
                border-white`}
               to={"/blog_content_list"}>
              
                 Blogs
               </NavLink>
               <NavLink 
              className={`${location.pathname==='/about'?"bg-white text-black":'bg-custom_green-400 text-white'} h-full flex     items-center lg:px-8 border-l-2 text-sm
              border-r-1 border-white`}
                to={"/about"}>
              
                  About
               </NavLink>
               <NavLink 
              className={`${location.pathname==='/faq'?"bg-white text-black":'bg-custom_green-400 text-white'} h-full flex    items-center lg:px-8 border-l-2 text-sm
              border-r-1 border-white`}
                to={"/faq"}>
              
                  FAQ
               </NavLink>
               <NavLink 
              className={`${location.pathname==='/contact_us'?"bg-white text-black":'bg-custom_green-400 text-white'} h-full flex lg:px-8    items-center  border-l-2 text-sm
              border-r-1 border-white`}
                to={"/contact_us"}>
              
                  Contacts
               </NavLink>
            </div>
        </div>
      </div>
      {showSideNav ? (
        <div
          className="fixed top-0 left-0 w-full h-full bg-custom_black-200 opacity-70 z-20 transition-all duration-300 ease-in"
          onClick={() => setShowSideNav(false)}
        ></div>
      ) : (
        ""
      )}
      <div className="lg:hidden">
        {showSideNav ? <SideNav setShowSideNav={setShowSideNav} /> : ""}
      </div>
    </>
  )
}

export default Navbar2;
