import { useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import Footer from '../components/footer/Footer';
import { useParams } from 'react-router-dom';
import MediaContentType from './MediaContentType';
import useTranslation from '../utils/translation';
import { useMediaContentService } from '../components/media-content/MediaContentService';
import { MediaContent } from '../types/MediaContent';
import DownloadSection from '../components/download/DownloadSection';
import Header from '../components/header/Header';
import circle from '../assets/images/circle-shape.webp';
import CustomButton from '../components/custom-button/CustomButton';
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
} from 'react-icons/fi';
import author11 from '../assets/images/author-11.webp';
import { FiPlay } from 'react-icons/fi';
import { GiDuration, GiLevelEndFlag } from 'react-icons/gi';
import { MdOndemandVideo } from 'react-icons/md';
import { FaBook } from 'react-icons/fa';
import { TbCertificate } from 'react-icons/tb';
import { Instructor } from '../components/Instructer/Instructor';
import { Reviewers } from '../components/Reviewers/Reviewers';
import { dateTimeFormatter } from '../utils/dateUtils';
import { formatDistanceToNow } from 'date-fns';

function CourseDescription({ description }: { description: any }) {
  const { translate } = useTranslation();
  return (
    <div className="mb-4">
      <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        {translate('description')}:
      </h3>
      <p className="text-gray-500 mt-4">{description}</p>

      {/* <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        Curriculum:
      </h3>
      <p className="text-gray-500 mt-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
        veniam maxime ut dolor, quisquam aliquam. Eius, sed! Ipsa adipisci
        cumque, quisquam reiciendis tempora, quibusdam molestiae rem nam
        deleniti, labore minus?
      </p>
      <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        Certification:
      </h3>
      <p className="text-gray-500 mt-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
        veniam maxime ut dolor, quisquam aliquam. Eius, sed! Ipsa adipisci
        cumque, quisquam reiciendis tempora, quibusdam molestiae rem nam
        deleniti, labore minus?
      </p> */}
    </div>
  );
}

function MediaVideo () {
  const [activeLink, setActiveLink] = useState(Number);
  const [url, setUrl] = useState('');
  const [youtube_url, setYoutubeUrl] = useState('');
  const mediaContentService = useMediaContentService();
  const [mediaContent, setMediaContent] = useState<MediaContent>();
  const [tab, setTab] = useState('description');
  const {translate} = useTranslation()
  let { id } = useParams();


  useEffect(() => {
    mediaContentService.getMediaContent(id?id:'').then(({data: content}) => {
      setMediaContent(content);
      if(content?.youtube_url){
        setYoutubeUrl(content?.youtube_url);
      }
      setUrl(content?.url)
      setActiveLink(content?.content_type)
    })
  }, []);

  const tabs: { [id: string]: JSX.Element } = {
    description: <CourseDescription description={mediaContent?.description} />,
    // instructor: <Instructor instructors={course?.instructor} />,
    reviews: <Reviewers id={id} />,
  };

  const changeTab = (selTab: string) => {
    setTab(selTab);
  };

  const [isCopied, setIsCopied] = useState(false); // State to track copy status

  const handleCopy = () => {
    var url = ""
    if (!url) url = window.location.href; // Default to current page URL if no URL provided
    navigator.clipboard.writeText(url); // Copy URL to clipboard
    setIsCopied(true); // Update copy status
    setTimeout(() => setIsCopied(false), 2000); // Reset copy status after 2 seconds
  };
 

  const timeFormater=(time:string="2023-12-30T09:26:36.420674Z")=>{
        
    return formatDistanceToNow(new Date(time), { addSuffix: true });

  
}


  return (
    <div className=''>
      <Header title="My" underlined={translate('courses')} activeTab="courses" />
      <div className="container mx-auto pt-20 flex my-36 md:my-60 flex-wrap flex-col xl:flex-row items-start">
        <div className="relative overflow-hidden basis-full lg:basis-7/12">
          <div className="relative overflow-hidden">
          <MediaContentType
                    contentType={activeLink}
                    url={youtube_url?youtube_url:url}
                  />
          </div>
          <h2 className="text-2xl font-medium mt-7 text-custom_black leading-6">
            {mediaContent?.title}
          </h2>
          <div className="flex items-center pt-2 justify-between">
           
            <div className="flex-1 pl-4 pb-1 text-gray-500">
            Posted 
              { " "+ timeFormater(mediaContent?.create_date)}
            </div>
          </div>
          <div className="pt-7">
            <div className="bg-custom_light_green rounded-lg p-4 pt-1">
              <ul className="flex justify-center">
                <li className="p-2 pb-0">
                  <CustomButton
                    text={translate('description')}
                    fun={() => changeTab('description')}
                    form={tab !== 'description' ? 'edge-transparent' : ''}
                  />
                </li>
                {/*<li className="p-2 pb-0">
                  <CustomButton
                    text="Instructor"
                    fun={() => changeTab('instructor')}
                    form={tab !== 'instructor' ? 'edge-transparent' : ''}
                  />
                </li>*/}
                <li className="p-2 pb-0">
                  <CustomButton
                    text="Comments"
                    fun={() => changeTab('reviews')}
                    form={tab !== 'reviews' ? 'edge-transparent' : ''}
                  />
                </li>
              </ul>
            </div>
            {tabs[tab]}
          </div>
        </div>

        <div className="lg:basis-5/12">
          <div className="w-11/12 ml-auto">
            {/* <div className="bg-custom_light_green p-10 border border-custom_green-900 rounded-xl">
              <h1 className="text-3xl font-bold text-custom_green-900 text-center">
              </h1>
            </div> */}
            <div className="mt-12">
              <h4 className="text-2xl font-medium -mt-2 mb-0">Share  Post:</h4>
              <button onClick={handleCopy} className={`relative overflow-hidden w-full cursor-pointer mt-5 leading-[3.75rem] rounded-xl text-sm sm:text-lg sm:leading-[3.75rem] font-medium transition-all duration-300 ease-in inline-block px-8 whitespace-nowrap bg-custom_green-900 text-white hover:text-custom_green-900 hover:bg-white ${isCopied ? 'copied' : ''}`}>
                  {isCopied ? 'Blog Link Copied' : 'Copy Blog Link'}
                </button>

              <ul className="flex justify-between mt-6 flex-wrap">
                <li className="pt-2 mr-2">
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiFacebook></FiFacebook>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href='https://www.linkedin.com/'
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiLinkedin></FiLinkedin>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiTwitter></FiTwitter>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noopener"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
                    flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiInstagram></FiInstagram>
                    </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MediaVideo;
