import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import CustomButton from "../components/custom-button/CustomButton";
import CustomCard from "../components/custom-card/CustomCard";
import CustomText from "../components/custom-text/CustomText";
import about from '../assets/images/about.webp';

function FAQ() {
    return (
        <>
            <Header title="Frequently Asked" underlined="Question" activeTab="faq" />
            {/* <div className="container mx-auto pt-20 columns-1 lg:columns-2">
                <div>
                    <img src={about} alt="About" className="w-full rounded-xl" />
                </div>
                <div className="mt-12 max-w-lg lg:ml-auto">
                    <h5 className="font-medium text-custom_green-900 mb-5 text-xl">Welcom to etunbi-media</h5>
                    <h2 className="text-4xl font-medium lg:text-3xl">You can join with etunbi-media and upgrade your skill for your <CustomText text="bright future." />
                    </h2>
                    <p className="mt-6 text-sm">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Blanditiis, doloremque tempore nihil assumenda quisquam debitis quis tenetur voluptates autem, perspiciatis recusandae fugiat sunt perferendis reiciendis sed et ipsum minus. Temporibus.</p>
                    <CustomButton text="Start A Course" />
                </div>
            </div> */}
            <div className=" w-full my-36 md:my-60">
                <div className="container mx-auto pt-6 pb-10 px-9 lg:px-20">
                    <div className="flex flex-wrap bg-custom_green-100 rounded-xl pl-0 mb-0 w-full">
                        <div className="flex-grow-0 lg:flex-grow basis-0 px-1 pt-4 pb-0 sm:p-4">
                            <CustomButton form="edge" text="What is Genocide" />
                        </div>
                        <div className="flex-grow-0 lg:flex-grow basis-0 px-1 pt-4 pb-0 sm:p-4">
                            <CustomButton form="edge" text="What is cultural politics" />
                        </div>
                        <div className="flex-grow-0 lg:flex-grow basis-0 px-1 pt-4 pb-0 sm:p-4">
                            <CustomButton form="edge" text="What is civil revolution" />
                        </div>
                        <div className="flex-grow-0 lg:flex-grow basis-0 px-1 pt-4 pb-0 sm:p-4">
                            <CustomButton form="edge" text="Do you about liberal democracy?" />
                        </div>
                        <div className="flex-grow-0 lg:flex-grow basis-0 px-1 pt-4 pb-0 sm:p-4">
                            <CustomButton form="edge" text="The seventh Moa tewahdo questions" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 mt-8">
                        <CustomCard light={true}>
                            <div className="flex flex-wrap items-center">
                                <div className="lg:w-5/12">
                                    <h4 className="text-xl group-hover:text-white max-w-sm font-medium text-custom_black-200">
                                        What are the sysmptoms of racism?
                                    </h4>
                                </div>
                                <div className="lg:w-7/12 mt-7 group-hover:text-white">
                                    <p className="mb-4">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industry's standard dummy text ever since the 1500 when un known printer took make a type specimen typesetting industry lorem Ipsum has been the industry's standard dummy text
                                    </p>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industry's standard dummy text ever since the 1500 when un known printer took make a type specimen typesetting industry lorem Ipsum has been the industry's standard dummy text
                                    </p>
                                </div>
                            </div>
                        </CustomCard>
                        <CustomCard light={true}>
                            <div className="flex flex-wrap items-center">
                                <div className="lg:w-5/12">
                                    <h4 className="text-xl group-hover:text-white max-w-sm font-medium text-custom_black-200">
                                        What are the patterns of ethiopia genocide?
                                    </h4>
                                </div>
                                <div className="lg:w-7/12 mt-7 group-hover:text-white">
                                    <p className="mb-4">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industry's standard dummy text ever since the 1500 when un known printer took make a type specimen typesetting industry lorem Ipsum has been the industry's standard dummy text
                                    </p>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industry's standard dummy text ever since the 1500 when un known printer took make a type specimen typesetting industry lorem Ipsum has been the industry's standard dummy text
                                    </p>
                                </div>
                            </div>
                        </CustomCard>
                    </div>
                    <div className="mt-12 flex items-center justify-center">
                        <CustomButton text="Other's Question" />
                    </div>
                </div>
            </div>
            <DownloadSection />
            <Footer />
        </>
    );
}

export default FAQ;
