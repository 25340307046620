import Footer from "../components/footer/Footer";
import { FaStar, FaSearch, FaShare, FaPlus, FaArchive, FaAngleRight, FaAngleLeft, FaClock, FaBookOpen, FaQuoteLeft } from "react-icons/fa"
import { MenuItem } from "../types/MenuItems";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTranslation from "../utils/translation";
import useLoading from "../components/loading/LoadingHook";
import { Blog } from "../types/Blog";
import Advert from "../components/advert/Advert";
import Navbar from "../components/navbar/Navbar";
import blog_news from "../assets/images/blog_news.png";
import course1 from "../assets/images/courses-01.webp";
import shape12 from "../assets/images/shape-12.webp";
import shape13 from "../assets/images/shape-13.svg";
import useCategoryService from "../components/category/CategoryService";
import { FetchData } from "../types/FetchData";
import { Category } from "../types/Category";
import { useBlogService } from "../components/blog/blogService";
import CustomButton from "../components/custom-button/CustomButton";
import { dateTimeFormatter } from "../utils/dateUtils";
import Header from "../components/header/Header";
import { formatDistanceToNow } from 'date-fns';
import Loading from "../components/loading/Loading";
import Loading2 from "../components/loading/Loading2";
export const options: MenuItem[] = [
    {
        id: 'share',
        name: 'Share',
        link: "/",
        icon: <FaShare />
    },
    {
        id: 'creat_collection',
        name: 'Create Collection',
        link: "/",
        icon: <FaPlus />

    },
    {
        id: 'favourite',
        name: 'Favourite',
        link: "/",
        icon: <FaStar />
    },
    {
        id: 'archive',
        name: 'Archive',
        link: "/",
        icon: <FaArchive />
    }
];




function BlogList() {

    const [mediaContent, setBlog] = useState<Blog[]>([])
    const [categories, setCategories] = useState<FetchData<Category>>();
    const loading = useLoading()
    const [id, setId] = useState('');
    const [count, setCount] = useState(0);
    const { translate } = useTranslation();
    const blogContent = useBlogService();
    const categoryService = useCategoryService();
    const [cursor, setCursor] = useState("")
    const [filterText, setFilterText] = useState("")
    const [category_id, setCategoryId] = useState<any>(null)
    const [item_count, setItemCount] = useState(0)
    useEffect(() => {
        loading.startLoading()
        searchBlogs();
        searchCategory();
    }
        , [category_id, cursor, filterText])

    const searchCategory = () => {
        categoryService.getCategories({ pageSize: 5, cursor: cursor, searchText: "" }).then(({ data: res }) => {
            setCategories(res)
        })
    }
    const handleClick = (e: any) => {
        e.preventDefault();
        if (count === 1) {
            hanldeClose(e); setCount(0)
        } else { setId(e.target.id); setCount(1); }
    }

    const hanldeClose = (e: any) => {
        setId('');
    }
    const searchBlogs = () => {
        blogContent.getBlogs({ category_id, filterText, limit: mediaContent ? mediaContent?.length! + 4 : 4 }).then(
            ({ data: values }) => {
                setBlog(values.results)
                setItemCount(values.count)
                loading.stopLoading()
            }
        ).catch(error => loading.stopLoading())
    }
    
    const timeFormater=(time:string)=>{
        
        return formatDistanceToNow(new Date(time), { addSuffix: true });

      
    }


    return (
        <>
            <Header/>
           
            {/* <Loading {...loading} /> */}
            <div className=" w-full bg-custom_black-1200 my-36 md:my-60">
                <div className="w-full lg:px-4 xl:px-10  flex items-center flex-col justify-center   ">
                <div className="flex ml-16 md:ml-0 w-full flex-col pt-4 md:flex-row md:justify-between md:place-items-center ">
                        <div className="hidden md:block">
                            <h2 className="text-[20px] font-medium mb-0 leading-[1.4]">Latest
                                <span className="mx-2  relative text-custom_green-900 before:absolute before:bg-shape_11 before:bg-center before:bg-cover 
    before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2"> Blog 
                                </span>                                    of Tunbi Media
                            </h2>
                        </div>
                        <div className="relative max-w-[300px] md:max-w-[500px] w-full">
                            <form action="#">
                                <input type="text" name="" placeholder="Search Your media videos" id="" value={filterText} onChange={(e) => setFilterText(e.target.value)} className="w-full h-[50px] rounded-[50px] border-[1px] border-[solid] border-[rgba(48,146,85,0.2)] py-0 px-[30px] pr-[90px] outline-none transition-[all 0.3s ease-in-out 0s] " />
                                <button className="flex justify-center place-items-center absolute w-[50px] h-[50px] leading-[54px] text-center rounded-r-[50px] bg-[#deede4] border-0 top-[1px] right-[1px] text-[16px] text-custom_green-900">
                                    <FaSearch />
                                </button>
                            </form>
                        </div>
                    </div>
                     <div className="py-[40px] px-6 rounded-[10px] mt-[50px] relative box-border mx-4">
                        <div className="touch-pan-y mx-10 relative overflow-hidden list-none p-0 z-[1]">
                            <ul className="relative w-full h-full z-[1] transition-transform box-content p-0 m-0 flex flex-wrap justify-center items-center pl-0 mb-0 list-none transform-[translate3d(0,0,0)] ">
                                {loading.loading?(<Loading2 {...loading}/>
                            ):categories?.results.map((value, index) => (
                                    <li className="flex-shrink-0  relative transition-transform mt-4 mr-2 w-[250px]" key={value.id}>
                                        <button
                                            className={`${category_id === value.id ? 'border-custom_green-900' : ''
                                                } w-full min-h-[60px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-[15px] font-medium transition-[all 0.3s ease-in-out 0s] px-[5px] py-0  text-overflow-ellipsis active:border-custom_green-900 active:text-custom_green-900 hover:border-custom_green-900 hover:text-custom_green-900`}
                                            onClick={() => { 
                                                if (category_id == value.id )
                                                    setCategoryId(null)
                                                else 
                                                    setCategoryId(value.id)

                                            }}
                                        >
                                           <span> {value.category_name} </span> 
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <span className="absolute left-0 top-0 pointer-events-none opacity-0 z-[-1000] list-none"></span>
                        </div>

                        <button className="after:content-[''] after:display-none  right-[10px] opacity-[1] w-[40px] h-[40px] leading-[40px] bg-white rounded-[50%] shadow-[0px 0px 20px 0px rgba(48,146,85,0.05)] text-[#212832] cursor-pointer outline-none transition-[all 0.3s ease-in-out 0s] left-auto absolute top-[35%] z-[10] flex place-items-center justify-center" onClick={() => {  }} ><FaAngleRight /></button>
                        <button className="after:content-[''] after:display-none  left-[10px] opacity-[1] w-[40px] h-[40px] leading-[40px] bg-white rounded-[50%] shadow-[0px 0px 20px 0px rgba(48,146,85,0.05)] text-[#212832] cursor-pointer outline-none transition-[all 0.3s ease-in-out 0s] left-auto absolute top-[35%] z-[10] flex place-items-center justify-center" onClick={() => {  }}><FaAngleLeft /></button>
                    </div>
                        

                    <div className="box-border w-full">
                        <div className="pt-4 sm:pt-[20px] ">
                            <div className="flex flex-wrap w-full items-start justify-center">
                                {loading.loading?(<Loading2 {...loading}/>
                            ):
                                 mediaContent.map(media=><>
                                <Link to={`/blog_content_detail/${media?.id}`} className=" md:max-w-[400px] mb-5 flex flex-col md:mr-2  pb-5 rounded-lg border-2 border-gray-300 text-justify">
                                     <img className="h-[250px] overflow-hidden " src={media?.thumbnail_image} alt="" />
                                     <span className="text-gray-400 text-[13px] px-3 self-end justify-self-end my-2">Posted  {timeFormater(media.create_date)}</span>
                                     <div className="px-3">
                                         <h3 className="text-sm font-medium">{media?.title}</h3>
                                         <p className="text-gray-600 text-sm">{media.description.slice(0,100)}
                                            <span className="text-custom_green-200">...read more</span> </p>
                                     </div>
                                </Link>
                                 </>

                                 )}
                            </div>
                        </div>
                    </div>

                    <div className="pt-10 sm:pt-[80px] w-full p-5 sm:p-0">
                        <div className="pt-2 w-full flex justify-center">
                            {
                                item_count !== mediaContent.length ?
                                <button type="button" onClick={searchBlogs} className="relative  text-white
                        bg-custom_green-900 px-7 mb-28 py-3 sm:py-5 my-5 mx-10 sm:mx-96    rounded-xl hover:scale-105 z-20">Read More ...</button>
                                :""
                            }
                            
                        </div>
                        <div className="w-full">
                            <div className="bg-[#6ea2ea] rounded-[10px] px-5 sm:px-[100px]  pt-[20px] pb-[50px] mb-[40px] relative">
                                <img src={shape12} alt="" className="absolute bottom-[30px] left-[30px] max-w-full animate-custom-spin" />
                                <img src={shape12} alt="" className="absolute top-[30px] right-[30px] max-w-full animate-custom-spin" />
                                <img src={shape13} alt="" className="absolute top-[70%] sm:top-[50%] right-[50%] sm:right-[34%] w-20 sm:w-[130px]" />
                                <div className="flex flex-col sm:flex-row place-items-center flex-wrap">
                                    <div className="w-full sm:w-1/2 flex-shrink-0 flex-grow-0 basis-auto max-w-full ">
                                        <div className="max-w-[415px] mt-[25px] pb-[20px] ">
                                            <h5 className="text-white font-medium mb-[20px] text-[20px]">Become A Supporter</h5>
                                            <h2 className="text-white font-medium mb-0 leading-[1.4]">You can join Tunbi as an <span className="text-white relative">Info Provider?</span> </h2>

                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2 flex-shrink-0 flex-grow-0 basis-auto max-w-full text-right mt-[30px] ">
                                        <div className=" ">
                                            <Link to={"/contact_us"} className="font-medium text-custom_green-900 bg-white text-[16px]  hover:text-custom_green-900
                                    mt-[13px] inline-block leading-[3.75rem] py-0 px-[2.188rem] text-[1.125rem] rounded-[10px]  border-custom_green-900">Drop Information</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="pt-[40px] pb-[80px] ">
                        <div className="w-full mx-auto ">
                        </div>
                    </div>
                </div>

            </div>
            <div>

            </div>

            <Footer />

        </>
    );
}

export default BlogList;
