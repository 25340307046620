import useApi from '../../utils/api';
import { Content, ContentSideNav, Question } from '../../types/Course';
import { Information } from '../../types/UserItem';

export const useContactUsService = () => {
    const {commonApi} = useApi()

 const addContactUs = (information: Information) => {
    const response = commonApi.post<Information>(
        'feedback/information/', information,
      //   {headers: {
      //     'content-type': 'application/json'
      // }}
    );
    return response;
};

return {addContactUs}
}
