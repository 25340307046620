import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import CustomText from "../components/custom-text/CustomText";
import CustomButton from "../components/custom-button/CustomButton";
import CustomCard from "../components/custom-card/CustomCard";
import { FaStar, FaQuoteLeft } from "react-icons/fa"
import data from '../components/carousel/data.json';
import useTranslation from "../utils/translation";
import useCarousel from "../components/carousel/carouselHook";
import { useEffect, useState } from "react";
import useToast from "../components/toast/ToastHook";
import useLoading from "../components/loading/LoadingHook";
import { Information } from "../types/UserItem";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomInput from "../components/custom-input/CustomInput";
import CustomDropdown from "../components/custom-input/CustomDropdown";
import useCategoryService from "../components/category/CategoryService";
import useFileService from "../components/file/FileService";
import { SelectItem } from "../types/MenuItems";
import CustomFileInput from "../components/custom-input/CustomFileInput";
import { FileTypes } from "../types/Enums";
import CustomTextArea from "../components/custom-input/CustomTextArea";
import { useContactUsService } from "../components/contact_us/ContactUsService";

export const InformationType = {
  VIDEO: 100,
  IMAGE: 101,
  DOCUMENT: 102,
  YOUTUBE_VIDEO: 103
} as const

function ContactUs() {
  const [categories, setCategories] = useState<SelectItem<string>[]>([]);
  const { register, setError, control, handleSubmit,reset, formState: { errors } } = useForm<Information>();
  const { translate } = useTranslation()
  const carousel = useCarousel(1)
  const loading = useLoading()
  const toast = useToast()
  const categoryService = useCategoryService()
  const fileService = useFileService()
  const contactUsService = useContactUsService()
  const contentTypes: SelectItem<number>[] = [
    { value: 100, label: "Video Content" },
    { value: 101, label: "Image Content" },
    { value: 102, label: "Document Content" },
    { value: 103, label: "Youtube video" },
  ];

  const [fileComponent, setFileComponent] = useState<JSX.Element>()
  const [id, setId] = useState<string>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
  }, [id])

  // const isYouTubeUrl = (url: string): boolean => {
  //   const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/(watch\?v=|embed\/|v\/)([a-zA-Z0-9_-]{11})/;    
  //   return youtubePattern.test(urlComponent);
  // }

  const urlComponent = (<CustomInput
    placeholder={translate('youtubeurl')}
    register={register}
    options={{
      required: 'url is required'
    }}
    label="url"
    error={errors.url}
  />)

  const fileComp = (content_type: keyof typeof FileTypes) => <CustomFileInput register={register}
    fileType={content_type}
    label="file_field"
    options={{
      required: "file is required"
    }}
    placeholder="File "
    error={errors?.file_field} />

  const handleContentType = (content_type?: keyof typeof FileTypes) => {
    if (content_type && [InformationType.IMAGE, InformationType.DOCUMENT, InformationType.VIDEO].some(value => value === content_type)) {
      setFileComponent(fileComp(content_type));
    } else if (content_type && content_type === InformationType.YOUTUBE_VIDEO) {
      setFileComponent(urlComponent)
    } else {
      setFileComponent(<></>)
    }
  }

  const getCategoryData = (filterText: string) => {
    categoryService.getCategories({
      pageSize: 5,
      cursor: '',
      searchText: filterText
    }).then(({ data: response }) => {
      setCategories(response.results.map((resp) => ({
        label: resp.category_name,
        value: resp.id
      })))
    })

  };

  useEffect(() => {
    getCategoryData("")
  }, [])


  const onSubmit: SubmitHandler<Information> = (data) => {
    const formData = new FormData();
    data.file_field?.length && formData.append(
      "url",
      data?.file_field[0] as unknown as Blob,
      (data.file_field[0] as unknown as File)?.name
    );
    formData.append("file_type", '101');

    if ([InformationType.IMAGE, InformationType.DOCUMENT, InformationType.VIDEO].some(value => value === data.content_type)) {
      fileService.addFile(formData).then(({ data: value }) => {
        data.url = value.url
        contactUsService.addContactUs(data).then(({ data: value }) => {
          reset(); 
          setFileComponent(undefined);
          setIsSubmitting(true);
         })

      });
    }
  }



  useEffect(() => {
    carousel.updateChildren(data.resources, component)
  }, [carousel.width])

  const component = (data: { title: string, link: string, imageUrl: string }) =>
  (<CustomCard key={data.title}>
    <div className="">
      <div className="relative ">

        <i className=" w-[30px] h-[30px] leading-[30px] text-center bg-custom_green-900 text-white text-[13px] rounded-[50%]  absolute bottom-[-13px] left-0 right-0 mx-auto my-0 flex justify-center place-items-center">
          {" "}
          <FaQuoteLeft />
        </i>
      </div>
      <span className="mt-[35px] text-[13px] font-700 text-[#ffba00] line-through mr-[5px] flex justify-center">
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar className="text-[#d0d0d0]" />
      </span>
    </div>
    <div className="text-center pt-[15px]">
      <p className="font-normal text-[15px] text-[#52565b] mb-0">
        Lorem Ipsum has been the industry's standard dummy text since
        the 1500s, when an unknown printer took a galley of type and
        scrambled it to make type specimen book has survived not five
        centuries but also the leap into electronic.
      </p>
      <h4 className="font-medium text-[22px] mb-0 mt-[16px]">
        Abebe Alemu{" "}
      </h4>
      <span className="text-custom_green-900 text-[14px] mt-[5px] block">
        Product Designer, Ethiopia
      </span>
    </div>
  </CustomCard>)

  return (

    <>

      <Header title={translate('about')} activeTab="about" />

      <div className="h-[500px] items-center flex relative overflow-hidden w-full my-36 md:my-60">
        <div className="w-full  ">

          <div className="container mx-5 md:mx-20">
            <div className="flex-col justify-center">
              <h5 className="font-medium  mb-5 text-xl">
                Welcom to Tunbi media
              </h5>
              <h2 className="text-4xl font-medium lg:text-3xl">
                You can supports us by 
                {" "}
              </h2>
              <h2 className="text-4xl font-medium lg:text-3xl">
                providing reliable information about our country situation
                {" "}
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* <CustomCard > */}
      <div className="container  py-20 flex    lg:columns-2 w-full justify-end">
      
        <div className="mt-[-10em] md:mt-[-15em] py-5 mx-2 md:mx-20 px-8 bg-white w-full md:w-[550px] rounded-md z-10 shadow-md ">
          <form onSubmit={handleSubmit(onSubmit)} >
            <CustomInput
              placeholder={translate('full_name')}
              register={register}
              label="full_name"
              error={errors.full_name}
              onInputChange={()=>setIsSubmitting(false)}
            />
            <CustomInput
              placeholder={translate('email')}
              register={register}
              label="email"
              error={errors.email}
            />
            <CustomInput
              placeholder={translate('location')}
              register={register}
              label="location"
              error={errors.location}
              options={{
                required: "Location is required"
              }}

            />
            <CustomDropdown
              placeholder={translate("content_type")}
              data={contentTypes}
              key="name"
              register={register}
              label='content_type'
              options={{
                required: 'Content Type is required'
              }}
              isSearchable={false}
              control={control}
              error={errors.content_type}
              onValueChange={handleContentType}
            />
            {fileComponent}
            <CustomTextArea
              placeholder={translate('description')}
              register={register}
              label="message"
              options={{
                required: "Message is required"
              }}
              error={errors.message}
            />
            {
              !isSubmitting
              ?<CustomButton type="submit" text="Submit Information" />
              :<CustomButton type="submit" text="Information is Submitted" />
            }
          </form>
        </div>
      </div>
      {/* </CustomCard> */}



      <Footer />
    </>
  );
}

export default ContactUs;
